export const RESUME_COLLECTION = "resumes";
export const EDUCATION_COLLECTION = "education";
export const EXPERIENCE_COLLECTION = "experience";
export const SKILLS_COLLECTION = "skill";
export const PERSONAL_INFO_COLLECTION = "personalInfo";
export const PROFESSIONAL_SUMMARY_COLLECTION = "professionalSummary";
export const AWARDS_COLLECTION = "awards";
export const PUBLICATION_COLLECTION = "publication";
export const LANGUAGE_COLLECTION = "language";
export const VOLUNTEERING_COLLECTION = "volunteering";

export const FREE_TRIAL_FEATURES = [
  "Unlimited Optimization with our AI-powered CV wizard",
  "Hold your Historical Repository in one place",
  "Craft tailored CVs targeting Job Title and/or Job Descriptions",
  "30 credits to use during the trial period",
  "Auto-renews to the Quick Job Plan",
  "Cancel anytime",
];

export const ENTERPRISE_FEATURES = [
  "Customized solutions for CV writing agencies, recruiters, and educational institutions",
  "Possibility to have your own customized Template",
  "Scalable credit packages and full access to all platform features",
  "Dedicated support and integration assistance",
  "Special pricing and programmes based on the volume and needs of the organization",
];

export const PRICING_TABLE = [
  {
    planName: "Speedy Rewrite",
    period: "14 Days",
    price: "£18.98",
    perWeek: "£9.99",
    priceId: "price_1Pr9uqRwhIDHSWqAzTrmmMvZ",
    planId: "pro_bi_weekly",
    credits: "100",
    features: [
      "Hold your career history in one place with our Career Repository",
      "Craft tailored CVs, targeting Job Title and/ or Job Descriptions",
      "Unlimited optimization from our Al-powered CV Wizard",
      // "100 accumulative credits to be used on uploads of existing CVs and downloads of your new tailored CVs*",
      "Auto-renewed subscription",
      "Cancel anytime",
    ],
  },
  {
    planName: "CV Pro",
    period: "1 Months",
    price: "£34.66",
    perWeek: "£8.66",
    priceId: "price_1PsnNdRwhIDHSWqAVedGVTvE",
    planId: "pro_quaterly",
    credits: "720",

    features: [
      "All the benefits of the Speedy Rewrite plan",
      // "20% more credits than the Quick Job Plan*",
      "Non-recurring payment",
      "Cancel anytime",
    ],
  },
  {
    planName: "CV Crafter",
    period: "3 Months",
    price: "£66.60",
    perWeek: "£5.55",
    credits: "3600",
    planId: "pro_yearly",

    priceId: "price_1PsnSDRwhIDHSWqAoo1XvFIh",
    features: [
      "All the benefits of the CV Pro plan",
      // "50% more credits than the Quick Job Plan",
      "Non-recurring payment",
      "Cancel anytime",
    ],
  },
];

// export const CREDITS_TABLE = [
//   {
//     credit: "100",
//     price: "£3.99",
//     priceId: "price_1P7C20RwhIDHSWqA0NTE8q2v",
//     planId: "credit_pack_100",
//   },
//   {
//     credit: "300",
//     price: "£6.99",
//     priceId: "price_1P7C2KRwhIDHSWqALLyUHPmt",
//     planId: "credit_pack_300",
//   },
//   {
//     credit: "500",
//     price: "£9.99",
//     priceId: "price_1P7C2gRwhIDHSWqAHWHeYjrS",
//     planId: "credit_pack_500",
//   },
//   {
//     credit: "1000",
//     price: "£17.99",
//     priceId: "price_1P7C32RwhIDHSWqANTxarYyY",
//     planId: "credit_pack_1000",
//   },
// ];
